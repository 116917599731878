.bee-game-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  background-color: bisque;
}

.bee-game-container > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bee-phaser-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bee-phaser-container > canvas {
  max-width: 100%;
  max-height: 100%;
}